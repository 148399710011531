import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on the acceptable use of company assets including internet access.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Infosec’s intentions for publishing an Acceptable Use Policy are not to
impose restrictions that are contrary to Data Migrators’ established
culture of openness, trust and integrity. Infosec is committed to
protecting Data Migrators’ employees, partners and the company from
illegal or damaging actions by individuals, either knowingly or
unknowingly.`}</p>
    <p>{`Internet/Intranet/Extranet-related systems, including but not limited to
computer equipment, software, operating systems, storage media, network
accounts providing electronic mail, WWW browsing, and FTP, are the
property of Data Migrators. These systems are to be used for business
purposes in serving the interests of the company, and of our clients and
customers in the course of normal operations. Please review Human
Resources policies for further details.`}</p>
    <p>{`Effective security is a team effort involving the participation and
support of every Data Migrators employee and affiliate who deals with
information and/or information systems. It is the responsibility of
every computer user to know these guidelines, and to conduct their
activities accordingly.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to outline the acceptable use of computer
equipment at Data Migrators. These rules are in place to protect the
employee and Data Migrators. Inappropriate use exposes Data Migrators
to risks including virus attacks, compromise of network systems
and services, and legal issues.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to the use of information, electronic and computing
devices, and network resources to conduct Data Migrators business or
interact with internal networks and business systems, whether owned or
leased by Data Migrators, the employee, or a third party. All
employees, contractors, consultants, temporary, and other workers at
Data Migrators and its subsidiaries are responsible for exercising
good judgment regarding appropriate use of information, electronic
devices, and network resources in accordance with Data Migrators
policies and standards, and local laws and regulation. Exceptions to
this policy are documented in section 5.2`}</p>
    <p>{`This policy applies to employees, contractors, consultants, temporaries,
and other workers at Data Migrators, including all personnel
affiliated with third parties. This policy applies to all equipment that
is owned or leased by Data Migrators.`}</p>
    <h2>{`Policy`}</h2>
    <h3>{`General Use and Ownership`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Data Migrators proprietary information stored on electronic and computing devices whether owned or leased by Data Migrators, the employee or a third party, remains the sole property of Data Migrators. You must ensure through legal or technical means that proprietary information is protected in accordance with the `}<em parentName="p">{`Data Protection Standard.`}</em></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You have a responsibility to promptly report the theft, loss or unauthorized disclosure of Data Migrators proprietary information.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You may access, use or share Data Migrators proprietary information only to the extent it is authorized and necessary to fulfill your assigned job duties.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Employees are responsible for exercising good judgment regarding the reasonableness of personal use. Individual departments are responsible for creating guidelines concerning personal use of Internet/Intranet/Extranet systems. In the absence of such policies, employees should be guided by departmental policies on personal use, and if there is any uncertainty, employees should consult their supervisor or manager. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`For security and network maintenance purposes, authorized individuals within Data Migrators may monitor equipment, systems and network traffic at any time, per Infosec\\‘s `}<em parentName="p">{`Audit Policy`}</em>{`. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Data Migrators reserves the right to audit networks and systems on a periodic basis to ensure compliance with this policy. `}</p>
      </li>
    </ol>
    <h3>{`Security and Proprietary Information`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`All mobile and computing devices that connect to the
internal network must comply with the `}<em parentName="p">{`Minimum Access
Policy`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`System level and user level passwords must comply with the
`}<em parentName="p">{`Password Policy`}</em>{`. Providing access to another individual,
either deliberately or through failure to secure its access,
is prohibited.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All computing devices must be secured with a
password-protected screensaver with the automatic activation
feature set to 10 minutes or less. You must lock the screen
or log off when the device is unattended.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Postings by employees from a Data Migrators email address
to newsgroups should contain a disclaimer stating that the
opinions expressed are strictly their own and not
necessarily those of Data Migrators, unless posting is in
the course of business duties.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Employees must use extreme caution when opening e-mail
attachments received from unknown senders, which may contain
malware.`}</p>
      </li>
    </ol>
    <h3>{`Unacceptable Use`}</h3>
    <p>{`The following activities are, in general, prohibited. Employees may be
exempted from these restrictions during the course of their legitimate
job responsibilities (e.g., systems administration staff may have a need
to disable the network access of a host if that host is disrupting
production services).`}</p>
    <p>{`Under no circumstances is an employee of Data Migrators authorized to
engage in any activity that is illegal under local, state, federal or
international law while utilizing Data Migrators-owned resources.`}</p>
    <p>{`The lists below are by no means exhaustive, but attempt to provide a
framework for activities which fall into the category of unacceptable
use.`}</p>
    <h3>{`System and Network Activities`}</h3>
    <p>{`The following activities are strictly prohibited, with no exceptions:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Violations of the rights of any person or company protected by
copyright, trade secret, patent or other intellectual property, or
similar laws or regulations, including, but not limited to, the
installation or distribution of “pirated” or other software
products that are not appropriately licensed for use by Data Migrators.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Unauthorized copying of copyrighted material including, but not
limited to, digitization and distribution of photographs from
magazines, books or other copyrighted sources, copyrighted music,
and the installation of any copyrighted software for which Data Migrators
or the end user does not have an active license is strictly
prohibited.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Accessing data, a server or an account for any purpose other than
conducting Data Migrators business, even if you have authorized
access, is prohibited.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Exporting software, technical information, encryption software or
technology, in violation of international or regional export control
laws, is illegal. The appropriate management should be consulted
prior to export of any material that is in question.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Introduction of malicious programs into the network or server (e.g.,
viruses, worms, Trojan horses, e-mail bombs, etc.).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Revealing your account password to others or allowing use of your
account by others. This includes family and other household members
when work is being done at home.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Using a Data Migrators computing asset to actively engage in
procuring or transmitting material that is in violation of sexual
harassment or hostile workplace laws in the user\\‘s local
jurisdiction.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Making fraudulent offers of products, items, or services originating
from any Data Migrators account.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Making statements about warranty, expressly or implied, unless it is
a part of normal job duties.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Effecting security breaches or disruptions of network communication.
Security breaches include, but are not limited to, accessing data of
which the employee is not an intended recipient or logging into a
server or account that the employee is not expressly authorized to
access, unless these duties are within the scope of regular duties.
For purposes of this section, “disruption” includes, but is not
limited to, network sniffing, pinged floods, packet spoofing, denial
of service, and forged routing information for malicious purposes.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Port scanning or security scanning is expressly prohibited unless
prior notification to Infosec is made.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Executing any form of network monitoring which will intercept data
not intended for the employee\\‘s host, unless this activity is a
part of the employee\\‘s normal job/duty.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Circumventing user authentication or security of any host, network
or account.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Introducing honeypots, honeynets, or similar technology on the
Data Migrators network.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Interfering with or denying service to any user other than the
employee\\‘s host (for example, denial of service attack).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Using any program/script/command, or sending messages of any kind,
with the intent to interfere with, or disable, a user\\‘s terminal
session, via any means, locally or via the
Internet/Intranet/Extranet.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Providing information about, or lists of, Data Migrators employees
to parties outside Data Migrators.`}</p>
      </li>
    </ol>
    <h3>{`Email and Communication Activities`}</h3>
    <p>{`When using company resources to access and use the Internet, users must
realize they represent the company. Whenever employees state an
affiliation to the company, they must also clearly indicate that “the
opinions expressed are my own and not necessarily those of the
company”. Questions may be addressed to the IT Department`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Sending unsolicited email messages, including the sending of “junk
mail” or other advertising material to individuals who did not
specifically request such material (email spam).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Any form of harassment via email, telephone or paging, whether
through language, frequency, or size of messages.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Unauthorized use, or forging, of email header information.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Solicitation of email for any other email address, other than that
of the poster\\‘s account, with the intent to harass or to collect
replies.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Creating or forwarding “chain letters”, “Ponzi” or other
“pyramid” schemes of any type.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use of unsolicited email originating from within Data Migrators’
networks of other Internet/Intranet/Extranet service providers on
behalf of, or to advertise, any service hosted by Data Migrators
or connected via Data Migrators’ network.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Posting the same or similar non-business-related messages to large
numbers of Usenet newsgroups (newsgroup spam).`}</p>
      </li>
    </ol>
    <h3>{`Blogging and Social Media`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Blogging by employees, whether using Data Migrators’ property and
systems or personal computer systems, is also subject to the terms
and restrictions set forth in this Policy. Limited and occasional
use of Data Migrators’ systems to engage in blogging is
acceptable, provided that it is done in a professional and
responsible manner, does not otherwise violate Data Migrators’
policy, is not detrimental to Data Migrators’ best interests,
and does not interfere with an employee\\‘s regular work duties.
Blogging from Data Migrators’ systems is also subject to
monitoring. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Data Migrators’ Confidential Information policy also applies to
blogging. As such, Employees are prohibited from revealing any
Data Migrators confidential or proprietary information, trade secrets
or any other material covered by Data Migrators’ Confidential
Information policy when engaged in blogging.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Employees shall not engage in any blogging that may harm or tarnish
the image, reputation and/or goodwill of Data Migrators and/or
any of its employees. Employees are also prohibited from making
any discriminatory, disparaging, defamatory or harassing comments
when blogging or otherwise engaging in any conduct prohibited by
Data Migrators’ `}<em parentName="p">{`Non-Discrimination and Anti-Harassment`}</em>{`
policy.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Employees may also not attribute personal statements, opinions or
beliefs to Data Migrators when engaged in blogging. If an
employee is expressing his or her beliefs and/or opinions in
blogs, the employee may not, expressly or implicitly, represent
themselves as an employee or representative of Data Migrators.
Employees assume any and all risk associated with blogging.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Apart from following all laws pertaining to the handling and
disclosure of copyrighted or export controlled materials,
Data Migrators’ trademarks, logos and any other Data Migrators
intellectual property may also not be used in connection
with any blogging activity`}</p>
      </li>
    </ol>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, business tool reports, internal
and external audits, and feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul">{`Data Classification Policy`}</li>
      <li parentName="ul">{`Data Protection Standard`}</li>
      <li parentName="ul">{`Social Media Policy`}</li>
      <li parentName="ul">{`Minimum Access Policy`}</li>
      <li parentName="ul">{`Password Policy`}</li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`The following definition and terms can be found in the `}<a parentName="p" {...{
        "href": "https://www.sans.org/security-resources/glossary-of-terms/"
      }}>{`SANS Glossary`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`Blogging`}</li>
      <li parentName="ul">{`Honeypot`}</li>
      <li parentName="ul">{`Honeynet`}</li>
      <li parentName="ul">{`Proprietary Information`}</li>
      <li parentName="ul">{`Spam`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      